/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import womanPark from '../images/heroes/woman-at-park.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import CobraBanner from '../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  Link,
  List,
  ListItem,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()
  const brandyList = ['disclaimer-aet-faq']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Aetna Medicare FAQs | Call 855-200-5724 For Answers',
          description:
            "We've most likely answered all of your Questions regarding Aetna Medicare plans, ID cards, when to enroll, & how to sign up. Click here!",
        },
        path: '/faq',
        promoCode: '120775',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Aetna Medicare FAQs | Call 855-200-5724 For Answers',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-top"
              image={
                <img src={womanPark} alt="elderly woman walking in the park" />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare{' '}
                  </Typography>

                  <Typography variant="h4">
                    Frequently Asked Questions
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                Have questions about Medicare?
              </Typography>
              <Typography variant="body">
                Medicare can seem really complex and intimidating. If you have
                questions, you’ve come to the right place! Find your question
                below, then click or tap on it to reveal the answer. If you
                can’t find the answer you’re looking for here, speak to a
                licensed agent today!
              </Typography>
              <Accordion
                items={[
                  {
                    title: 'What is Medicare?',
                    content: (
                      <>
                        <Typography>
                          Medicare is a federally funded health care program
                          administered by The Centers for Medicare and Medicaid
                          Services (CMS), a division of the U.S. Department of
                          Health and Human Services (HHS).
                        </Typography>
                        <Typography>
                          Medicare began in 1965, the same year as Medicaid,
                          when President Lyndon B. Johnson signed a bill
                          launching both programs. The goal of Medicare was and
                          continues to be providing health care services to
                          those who might otherwise have a hard time accessing
                          them, specifically those 65 or older, younger people
                          with certain disabilities, and those who suffer from
                          end-stage renal kidney failure.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'What are the different “parts” of Medicare?',
                    content: (
                      <>
                        <Typography>
                          When it began, Medicare had two parts: Medicare Part A
                          (sometimes called “hospital insurance”) and Medicare
                          Part B (also known as “medical insurance”). Both parts
                          are still available today, and together they are
                          sometimes called “Original” or “Traditional” Medicare.
                          Original Medicare is offered through and administered
                          by the federal government.
                        </Typography>
                        <Typography>
                          Over the years, the government has introduced
                          additional parts to Medicare, available through
                          licensed private insurance companies, to help
                          enrollees gain access to more benefits.
                        </Typography>
                        <Typography>These parts include:</Typography>
                        <List>
                          <ListItem>Medicare Advantage (Part C)</ListItem>
                          <ListItem>
                            Medicare Part D (prescription drugs and medications)
                          </ListItem>
                          <ListItem>Medicare Supplement (Medigap)</ListItem>
                        </List>
                        <Typography>
                          For a list of the services covered under each part of
                          Medicare, please see the question, “What does Medicare
                          cover?” below.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'What is a Medicare “beneficiary”?',
                    content: (
                      <>
                        <Typography>
                          The term “beneficiary” refers to anyone enrolled in a
                          Medicare plan.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'What does Medicare cover?',
                    content: (
                      <>
                        <Typography>
                          Each part of Medicare covers a different set of
                          services or health care expenses. Here is a brief
                          overview of what each part covers.
                        </Typography>
                        <Typography>
                          <strong color="primary">Medicare Part A</strong>
                          <br />
                          <br />
                          Otherwise known as “hospital insurance,” Medicare Part
                          A covers a variety of emergency and long-term care
                          services. These services generally include:
                          <br />
                          <br />
                          <List>
                            <ListItem>Nursing homes</ListItem>
                            <ListItem>Hospital procedures and stays</ListItem>
                            <ListItem>Hospice care</ListItem>
                            <ListItem>
                              Home health care that occurs after an inpatient
                              stay
                            </ListItem>
                            <ListItem>
                              Blood transfusions that you may receive at a
                              hospital (above three pints annually)
                            </ListItem>
                          </List>
                        </Typography>
                        <Typography>
                          <strong color="primary">Medicare Part B</strong>
                          <br />
                          <br />
                          Sometimes called “medical insurance,” pays for a broad
                          range of preventative and other medical services.
                          These may include services such as:
                          <br />
                          <br />
                          <List>
                            <ListItem>Doctor visits</ListItem>
                            <ListItem>Screenings and lab tests</ListItem>
                            <ListItem>
                              Counseling, therapy, and mental health
                            </ListItem>
                            <ListItem>Shots and immunizations</ListItem>
                            <ListItem>Ambulance</ListItem>
                            <ListItem>Outpatient care</ListItem>
                            <ListItem>Surgeries</ListItem>
                            <ListItem>X-rays</ListItem>
                            <ListItem>Clinical research studies</ListItem>
                            <ListItem>
                              Limited outpatient prescription drugs
                            </ListItem>
                          </List>
                        </Typography>
                        <Typography>
                          <strong>Note:</strong> While many preventative
                          services will be 100 percent covered, others may be
                          billed on an 80/20 coinsurance basis (i.e., after you
                          meet your deductible, Medicare Part B pays for 80
                          percent of your care, and you pay for 20 percent).
                        </Typography>
                        <Typography>
                          <strong color="primary">
                            Medicare Part C (Medicare Advantage)
                          </strong>
                          <br />
                          <br />
                          Medicare Advantage plans, also known as “Medicare Part
                          C,” offer all the same benefits as Medicare Parts A
                          and B (“Original” Medicare) above, but many offer
                          additional insurance for services not covered under
                          Original Medicare.
                        </Typography>
                        <Typography>
                          Depending on your coverage area, your Medicare
                          Advantage plan may include one or more of the
                          following:
                          <br />
                          <br />
                          <List>
                            <ListItem>Vision</ListItem>
                            <ListItem>Dental</ListItem>
                            <ListItem>Hearing aids</ListItem>
                            <ListItem>Health and wellness programs</ListItem>
                            <ListItem>Gym memberships</ListItem>
                          </List>
                        </Typography>
                        <Typography>
                          Some Medicare Advantage plans may also include
                          prescription drug (Medicare Part D) benefits.
                        </Typography>
                        <Typography>
                          <strong color="primary">Medicare Part D</strong>
                          <br />
                          <br />
                          Medicare Part D covers a wide variety of prescription
                          drugs and medications. Each Medicare Part D plan
                          offers a different range of medications (known as a
                          “list” or “formulary”) at different rates (known as
                          “tiers”), depending on your coverage area, the cost of
                          medications, and whether the drugs you need are
                          generic or brand name.
                        </Typography>
                        <Typography>
                          <strong color="primary">
                            Medicare Supplement (Medigap)
                          </strong>
                          <br />
                          <br />
                          Medicare Supplement plans (also called “Medigap”
                          plans) help pay for some health care costs not covered
                          by your Original Medicare or Medicare Advantage plan.
                        </Typography>
                        <Typography>
                          These may include expenses such as:
                          <br />
                          <br />
                          <List>
                            <ListItem>Deductibles</ListItem>
                            <ListItem>Copayments</ListItem>
                            <ListItem>Coinsurance</ListItem>
                            <ListItem>Care that you receive overseas</ListItem>
                          </List>
                        </Typography>
                        <Typography>
                          <strong>Note:</strong> Contrary to what the name might
                          suggest, Medicare Supplement plans (or “Supplemental”
                          Medicare plans) do not cover prescription drugs or
                          medications. Prescription drugs are covered by
                          Medicare Part D mentioned above.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title:
                      'What’s the difference between Medicare and Medicaid?',
                    content: (
                      <>
                        <Typography>
                          Medicare is a federal program that provides health
                          care benefits for people 65 years and older, as well
                          as for some people who suffer from severe disabilities
                          or end-stage renal kidney failure. Medicare is
                          available to all eligible applicants, regardless of
                          income.
                        </Typography>
                        <Typography>
                          Medicaid, on the other hand, is a state- and
                          federally-run program designed to help lower-income
                          families and individuals gain access to health care
                          services.
                        </Typography>
                        <Typography>
                          In some cases, you may qualify for both Medicare and
                          Medicaid. This is known as “dual eligibility.”
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title:
                      'What’s the difference between Medicare and traditional, private health insurance?',
                    content: (
                      <>
                        <Typography>
                          Medicare is a federally administered program designed
                          to help people who might not otherwise qualify for a
                          private health insurance policy, so they can get
                          access to the health care they need. Specifically,
                          Medicare provides health insurance benefits for people
                          ages 65 and older and for people with certain
                          disabilities.
                        </Typography>
                        <Typography>
                          These benefits are provided to all eligible
                          applicants, no matter their income level and may
                          provide some benefits at little to no cost to
                          enrollees.
                        </Typography>
                        <Typography>
                          Private health insurance, on the other hand, is
                          commercially run. This generally means that you have a
                          greater range of options regarding coverage and price
                          when purchasing a policy. However, it also means that
                          before you can sign up for a policy, an insurance
                          company will perform a risk assessment based on a
                          number of factors, such as your age and your medical
                          history. Depending on the type of plan you choose your
                          risk assessment could cause a private health insurance
                          company to charge you a higher rate or deny you
                          coverage altogether.
                        </Typography>
                        <Typography>
                          <strong>Note:</strong> Depending on which plan you
                          enroll in, Medicare may be offered through the federal
                          government or through a private (but still federally
                          regulated) insurance company.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'When am I eligible for Medicare?',
                    content: (
                      <>
                        <Typography>
                          You may be eligible for Medicare benefits if one or
                          more of the following apply to you:
                          <br />
                          <br />
                          <List>
                            <ListItem>You are 65 years old or older</ListItem>
                            <ListItem>
                              You are younger than 65 but suffer from a
                              Medicare-approved disability
                            </ListItem>
                          </List>
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'When can I enroll in Medicare?',
                    content: (
                      <>
                        <Typography>
                          There are three primary enrollment periods for
                          Medicare plans.
                        </Typography>
                        <Typography>
                          <strong color="primary">
                            Initial Enrollment Period (IEP)
                          </strong>
                          <br />
                          <br />
                          Your Initial Enrollment Period (IEP) begins three
                          months before the month you turn 65 and ends three
                          months after the month of your 65th birthday. So, for
                          example, if you were born April 15, your IEP starts
                          January 1 and ends July 31.
                          <br />
                          <br />
                          However, if you were born on the first day of a given
                          month, the entire month before your birthday counts as
                          your birth month. In other words, if you were born on
                          May 1, your IEP would likewise start February 1 and
                          end July 31.
                          <br />
                          <br />
                          <strong>Note:</strong> If you are younger than 65,
                          your Initial Enrollment Period may be triggered by a
                          disability or other conditions.
                        </Typography>
                        <Typography>
                          <strong color="primary">
                            General Enrollment Period (GEP)
                          </strong>
                          <br />
                          <br />
                          If you don’t enroll during your Initial Enrollment
                          Period, you can enroll during what’s called the
                          General Enrollment Period (or “GEP”). The GEP begins
                          every year on January 1 and goes until March 31.
                          However, be warned: Enrolling during the GEP may mean
                          you have to pay a late enrollment fee to receive
                          benefits. Coverage begins on July 1.
                        </Typography>
                        <Typography>
                          <strong color="primary">
                            Annual Election Period (AEP)
                          </strong>
                          <br />
                          <br />
                          Once you’ve enrolled in a Medicare plan (either during
                          your Initial Enrollment Period or during the General
                          Enrollment Period), you have the option of making
                          changes to your plan during the Annual Election
                          Period, or “AEP.” The Annual Election Period, runs
                          each year from October 15 through December 7.
                          <br />
                          <br />
                          During AEP, you can make any of the following changes
                          to your existing Medicare plan elections:
                          <br />
                          <br />
                          <List>
                            <ListItem>
                              Replace your Original Medicare plan with a
                              Medicare Advantage plan
                            </ListItem>
                            <ListItem>
                              Add prescription drug coverage to your Original
                              Medicare
                            </ListItem>
                            <ListItem>
                              Switch from a Medicare Advantage plan to an
                              Original Medicare plan
                            </ListItem>
                            <ListItem>
                              Enroll in a Medicare Supplement (Medigap) plan
                            </ListItem>
                            <ListItem>
                              Cancel any of your existing plans
                            </ListItem>
                          </List>
                          <br />
                          <br />
                          <strong>Note:</strong> Certain life circumstances may
                          qualify you to enroll in a Medicare plan at a time
                          other than those listed above. This is known as the
                          Special Enrollment Period (or “SEP”). This may include
                          situations such as moving to a new area, living in a
                          nursing home, or qualifying for both Medicare and
                          Medicaid.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'What is the Medicare “donut hole?”',
                    content: (
                      <>
                        <Typography>
                          Many Medicare Part D plans offer a coverage gap
                          commonly referred to as the Medicare “donut hole.” In
                          a nutshell, this means that once you and your plan
                          have reached a specified amount for covered
                          medications you must pay any additional costs for
                          prescription drugs out of pocket until you meet your
                          annual limit.
                        </Typography>
                        <Typography>
                          The amount of your annual limit is determined by CMS
                          and may change from year to year. However, once your
                          annual limit is met, your coverage gap ends and your
                          plan will once again help pay for any additional drug
                          costs. Your coverage gap and annual limit “reset” at
                          the beginning of each calendar year.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'Are Medicare payments tax deductible?',
                    content: (
                      <>
                        <Typography>
                          The short answer? Yes. On your federal taxes, you can
                          deduct money that you spend on Medicare premiums as
                          long as you meet certain criteria set by the Internal
                          Revenue Service (IRS).
                        </Typography>
                        <Typography>
                          The amount you can deduct from your taxes will depend
                          on a number of factors, including your adjusted gross
                          income (AGI) and other medical expenses you may have
                          had in a given tax year. And keep in mind, too, that
                          the IRS may change its policies in a certain tax year
                          regarding deductions.
                        </Typography>
                        <Typography>
                          For more information about which medical or dental
                          expenses you can deduct from your federal taxes, visit
                          the{' '}
                          <a
                            href="https://www.irs.gov/help/ita/can-i-deduct-my-medical-and-dental-expenses"
                            target="_blank"
                          >
                            Internal Revenue Service’s website
                          </a>
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'Can I be dropped from Medicare?',
                    content: (
                      <>
                        <Typography>
                          If you are enrolled in an Original Medicare or a
                          Medicare Advantage plan (with or without prescription
                          medication coverage), you may lose your benefits if
                          you fail to pay your premiums or move outside your
                          coverage area.
                        </Typography>
                        <Typography>
                          With Original Medicare (Parts A and B), you’ll
                          typically receive two warnings in the mail urging you
                          to make a payment by a specific date.
                        </Typography>
                        <Typography>
                          Since Medicare Advantage (Part C) plans are offered
                          through Medicare-approved private insurance companies,
                          you may be dropped from your plan with or without
                          notice if you don’t pay your premiums.
                        </Typography>
                        <Typography>
                          <strong>Note:</strong> If you're having difficulty
                          making payments on your Aetna<sup>®</sup> Medicare
                          Advantage plan, we want to know! Please call us today
                          at <a href="tel:1-855-446-0414">1-855-446-0414</a>.
                          Calls will be answered by a licensed agent.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'What is the Medicare Modernization Act?',
                    content: (
                      <>
                        <Typography>
                          The Medicare Prescription Drug, Improvement, and
                          Modernization Act (or simply “Medicare Modernization
                          Act” or MMA), was signed by President George W. Bush
                          on December 8, 2003. The MMA represents the biggest
                          overhaul to Medicare since the program’s initial
                          launch in 1965.
                        </Typography>
                        <Typography>
                          Among other things, the MMA introduced Medicare Part
                          D, providing optional prescription drug and medication
                          coverage that could be added to an existing Original
                          Medicare or Medicare Advantage plan.
                        </Typography>
                        <Typography>
                          <strong>Note:</strong> For more information about
                          changes made in the Medicare Modernization Act, visit{' '}
                          <a
                            href="https://www.congress.gov/bill/108th-congress/house-bill/1"
                            target="_blank"
                          >
                            Congress’s website
                          </a>
                          .
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'How do I enroll in a Medicare plan?',
                    content: (
                      <>
                        <Typography>
                          The simplest and easiest way to enroll in a Medicare
                          plan is to{' '}
                          <Link to={`tel:${rotatedNumber}`}>
                            {rotatedNumber}.
                          </Link>
                          Your agent can answer any questions you may have and
                          help you find the right plan for you or your loved
                          ones.
                        </Typography>
                      </>
                    ),
                  },
                ]}
              />
              <br />
              <br />
              <Typography variant="h5" color="primary">
                Still have questions? No problem!
              </Typography>
              <Typography variant="body">
                Our friendly, licensed agents are standing by, ready to help.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>
        <CobraBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
